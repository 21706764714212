<template>
  <div
    class="header"
    :style="{
      'background-image':
        'url(' +
        '/images/navbar/'+this.theme+'/svg/'+ this.date.getHours()+'.svg' +
        ')'
    }"
  >
    <router-link to="/"
      ><img
        draggable="false"
        @contextmenu.prevent
        alt="Logo Chimboz"
        width="240"
        height="70"
        id="logo"
        :src="`/images/navbar/${this.theme}/logo.svg`"
    /></router-link>
    <div class="login flex centered">
      <div style="margin-top: -10px">
        <User
          :user="user || { name: 'Visiteur' }"
          id="username"
          style="display: inherit"
        />
        <button
          id="connect"
          class="flex centered"
          @click="logout"
          v-if="authenticated"
        >
          <img
            draggable="false"
            @contextmenu.prevent
            alt="Disconnect icon"
            src="/images/disconnect.svg"
          />
          {{ $t("navbar.logout") }}
        </button>
        <button v-else>
          <router-link
            to="/login"
            id="connect"
            class="flex centered"
          >
            <img
              draggable="false"
              @contextmenu.prevent
              alt="Connect icon"
              src="/images/connect.svg"
            />
            {{ $t("navbar.login") }}
          </router-link></button
        >
      </div>
      <Tiz
        style="margin-right: 17px"
        :avatar="user ? user.look.avatar : 0"
        :emote="user ? user.look.emote : 'neutral'"
        :hat="user ? user.look.hat : 1"
        :body="user ? user.look.body : 318"
        :shoe="user ? user.look.shoe : 606"
        :item0="user ? user.look.item0 : 792"
        :item1="user ? user.look.item1 : 868"
        :item2="user ? user.look.item2 : 938"
      />
    </div>
  </div>
  <div id="nav" class="flex">
    <router-link to="/"
      ><button class="nav-btn flex centered">
        <img
          draggable="false"
          @contextmenu.prevent
          alt="Home icon"
          class="nav-icon"
          src="/images/home.svg"
          width="20"
          height="20"
          style="margin: 2px; padding: 1px"/></button
    ></router-link>
      <a 
        @click="handleOpenTchat"
      ><button class="nav-btn flex centered">
        <div class="nav-text">
          <img
            draggable="false"
            @contextmenu.prevent
            alt="Tchat icon"
            class="nav-icon"
            src="/images/tchat.svg"
            width="28"
            height="21"
          />
          <StrokeText style="z-index: 1" class="btn-label">{{
            $t("navbar.play")
          }}</StrokeText>
        </div>
        <img
          draggable="false"
          @contextmenu.prevent
          alt="Arrow icon"
          class="arrow jitter"
          width="40"
          height="33"
          src="/images/arrow.svg"/></button
    ></a>
    <router-link v-if="authenticated" to="/account"
      ><button class="nav-btn flex centered">
        <div class="nav-text">
          <img
            draggable="false"
            @contextmenu.prevent
            alt="Account icon"
            class="nav-icon"
            src="/images/account.svg"
            width="21"
            height="21"
          />
          <StrokeText style="z-index: 1" class="btn-label">{{
            $t("navbar.account")
          }}</StrokeText>
        </div>
      </button></router-link
    >
    <router-link to="/games"
      ><button class="nav-btn flex centered">
        <div class="nav-text">
          <img
            draggable="false"
            @contextmenu.prevent
            alt="Games icon"
            class="nav-icon"
            src="/images/games.svg"
            width="20"
            height="21"
          />
          <StrokeText style="z-index: 1" class="btn-label">{{
            $t("navbar.games")
          }}</StrokeText>
        </div>
      </button></router-link
    >
    <router-link to="/members"
      ><button class="nav-btn flex centered">
        <div class="nav-text">
          <img
            draggable="false"
            @contextmenu.prevent
            alt="Member icon"
            class="nav-icon"
            src="/images/members.svg"
            width="21"
            height="21"
          />
          <StrokeText style="z-index: 1" class="btn-label">{{
            $t("navbar.members")
          }}</StrokeText>
        </div>
      </button></router-link
    >
    <router-link to="/bbs"
      ><button class="nav-btn flex centered">
        <div class="nav-text">
          <img
            draggable="false"
            @contextmenu.prevent
            alt="Community icon"
            class="nav-icon"
            width="21"
            height="21"
            src="/images/community.svg"
          />
          <StrokeText style="z-index: 1" class="btn-label">{{
            $t("navbar.community")
          }}</StrokeText>
        </div>
      </button></router-link
    >
  </div>
</template>

<script>
import StrokeText from "./StrokeText.vue";
import { mapGetters, mapState } from "vuex";
import eventBus from "../../module/eventBus";
export default {
  name: "Navbar",
  data() {
    return {
      date: new Date(),
      theme: 'classic'
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated"]),
    ...mapState("auth", ["user"])
  },
  components: {
    StrokeText
  },
  methods: {
    logout() {
      this.api.get('/api/logout').then((response) => {
        localStorage.removeItem('token');
        this.$store.dispatch("auth/logout");
        this.$router.push(this.$route.path == "/" ? "/login" : this.$route.path);
        //this.$router.push({ name: 'Login' });
      });
    },
    handleOpenTchat() {
      var gameSettings = {'windowInTab':true};
      if(localStorage.getItem('chimboz_config') != null) {
        gameSettings = JSON.parse(localStorage.getItem('chimboz_config'));
      }
      if(gameSettings['windowInTab'] == true || gameSettings['windowInTab'] == undefined) {
	      window.open("/tchat", '_blank');
      } else {
        window.open("/tchat","nom_popup","menubar=no, status=no, scrollbars=no, menubar=no, width=1024, height=600");
	    }
    }
  },
  created() {
    var background;
    var month = this.date.getMonth();
    switch(month){
      case 1:
        this.theme = 'winter';
        background = 'w';
      break;
      case 2:
        this.theme = 'winter';
        background = 'w';
      break;
      case 9:
        this.theme = 'halloween';
        background = 'h';
      break;
      case 11:
        this.theme = 'winter';
        background = 'w';
      break;
      case 12:
        this.theme = 'winter';
        background = 'w';
      break;
      default: this.theme = 'classic'; background = 'c'; break;
    }
    document.body.className = background + this.date.getHours();
  }
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 78px;
  background-position: center;
  background-size: cover;
}
#logo {
  float: left;
}
.login {
  text-align: right;
  justify-content: flex-end;
  padding: 12px 14px 0 0;
    align-items: center;
}
#username {
  font-family: "Pixelated Verdana 12";
  font-weight: bold;
  font-size: 16px;
  padding: 0 8px 8px 0;
  color: #069;
  text-shadow: 0 0 5px #fff, 0 0 5px #fff;
}
.theme-toggler {
  background: var(--light);
  border-radius: var(--round);
  height: calc(var(--font-size) * 2);
  background: var(--dark);
  align-content: center;
  justify-content: center;
  align-items: center;
}
.theme-toggler img {
  height: calc(var(--font-size) * 2);
}
#connect {
  font-family: "Pixelated Verdana 12";
  font-size: 16px;
  font-weight: normal;
  color: #000;
  border-radius: 100px;
  padding: 3px;
  margin-right: -20px;
  border: none;
  background: #fff;
  width: 130px;
}
#connect img {
  padding-right: 4px;
}
#connect:hover {
  background: #ade5f3;
  font-weight: bold;
  letter-spacing: 0;
}
.nav-btn {
  cursor: var(--pointer);
  height: 25px;
  background-image: linear-gradient(
    to bottom,
    var(--light) 0%,
    var(--pink) 50%,
    var(--dark-pink) 50%,
    var(--pink)
  );
  padding: 0 4%;
  font-size: var(--lg-font-size);
  border-width: 2px 1px;
  border-style: solid;
  border-color: var(--pink) var(--pink) var(--dark-pink) var(--pink);
  box-shadow: 0 2px 1px var(--dark);
}
#nav a:not(:first-child) {
  display: contents;
}
#nav a:nth-child(2) .nav-btn {
  border-left-color: var(--pink);
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  border-left-width: 5px;
}
#nav a:last-child .nav-btn {
  border-right-color: var(--pink);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  border-right-width: 5px;
}
.nav-icon {
  height: 100%;
  filter: saturate(1.5) hue-rotate(308deg);
}
.nav-btn:hover .nav-icon,
.nav-btn:active .nav-icon,
#nav a.router-link-exact-active .nav-icon {
  filter: none;
}
#nav a:first-child .nav-btn {
  border-right-color: var(--pink);
  border-left-color: var(--pink);
  background-image: radial-gradient(
    ellipse 120% 100% at 50% 0%,
    var(--light),
    var(--pink) 49%,
    var(--dark-pink) 51%,
    var(--pink)
  );
  border-radius: var(--border-radius);
  border-width: 2px 5px;
  margin-right: 12px;
}
#nav {
  justify-content: center;
  padding-bottom: 10px;
}
.nav-btn:hover,
.nav-btn:active,
#nav a.router-link-exact-active .nav-btn {
  background-image: linear-gradient(
    to bottom,
    var(--light),
    var(--orange) 50%,
    var(--dark-orange) 50%,
    var(--orange)
  );
  color: var(--light) !important;
  border-color: var(--orange) var(--orange) var(--dark-orange) var(--orange);
}
#nav a:first-child .nav-btn:hover,
#nav a:first-child .nav-btn:active,
#nav a:first-child.router-link-exact-active .nav-btn {
  background-image: radial-gradient(
    ellipse 120% 100% at 50% 0%,
    var(--light) 0%,
    var(--orange) 49%,
    var(--dark-orange) 51%,
    var(--orange)
  );
}
#nav a:nth-child(2) .nav-btn:hover,
#nav a:first-child .nav-btn:hover,
#nav a:nth-child(2) .nav-btn:active,
#nav a:first-child .nav-btn:active,
#nav a:first-child.router-link-exact-active .nav-btn,
#nav a:nth-child(2).router-link-exact-active .nav-btn {
  border-left-color: var(--orange);
}
#nav a:last-child .nav-btn:hover,
#nav a:first-child .nav-btn:hover,
#nav a:last-child .nav-btn:active,
#nav a:first-child .nav-btn:active,
#nav a:last-child.router-link-exact-active .nav-btn,
#nav a:first-child.router-link-exact-active .nav-btn {
  border-right-color: var(--orange);
}
a:hover {
  text-decoration: none;
}
.nav-btn:active {
  background-image: linear-gradient(
    to top,
    var(--light),
    var(--orange) 50%,
    var(--dark-orange) 50%,
    var(--orange)
  ) !important;
}
#nav a:first-child .nav-btn:active {
  background-image: radial-gradient(
    ellipse 120% 100% at 50% 0%,
    var(--orange),
    var(--dark-orange) 49%,
    var(--orange) 51%,
    var(--light)
  ) !important;
}
.nav-text {
  font-family: "Chimboz Heavy";
  font-weight: normal;
  font-size: var(--lg-font-size);
  height: 100%;
  fill: var(--text-button);
  stroke: var(--dark-pink);
  stroke-width: 3;
  display: flex;
  justify-content: center;
}
.btn-label,
.arrow {
  display: none;
}
@media (min-width: 600px) {
  .btn-label {
    display: inherit;
  }
  .nav-btn:hover .nav-text,
  .nav-btn:active .nav-text,
  #nav a.router-link-exact-active .nav-text {
    stroke: var(--dark-orange);
  }
  a:not(:first-child) .nav-btn {
    padding: 0 20px;
  }
  .nav-btn:hover .arrow {
    filter: saturate(1.2);
  }
  .arrow {
    display: inherit;
    margin-right: -30px;
    z-index: 2;
  }
}
</style>