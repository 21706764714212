import axios from 'axios';
export default {
  namespaced: true,
  state() {
    return {
      user: null,
    };
  },
  getters: {
    authenticated(state) {
      return state.user !== null;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    login ({ commit }) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      return axios.get('/api/user').then(({ data }) => {
        var split_ad = data.avatar_design.split(';');
        var color = '';
        switch(data.user_level){
          case '1': color = '#900'; break;
          case '2': color = '#326496'; break;
          case '5': color = '#6A6A6A'; break;
          case '6': color = '#FF3300'; break;
        }
        commit("setUser", {
          name: data.pseudo,
          id: data.id,
          color: color,
          role: data.user_level,
          credits: parseInt(data.money),
          look:{
            avatar: 0,
            emote: data.avatar_mood,
            hat: Number(split_ad[1]),
            body: Number(split_ad[2]),
            shoe: Number(split_ad[3]),
            item0: Number(split_ad[4]),
            item1: Number(split_ad[5]),
            item2: Number(split_ad[6]),
          }
        });
      });
    },
    logout({ commit }) {
      commit("setUser", null);
    },
  },
};