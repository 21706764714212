import axios from "axios";
import eventBus from "./eventBus";
import store from "../store";
import router from "../router";

function refreshToken (token) {
  const payload = {
    token: token
  }
  const headers = {
    'Accept': "application/json",
    'Content-Type': 'application/json',
    'Authorization': token ? `Bearer ${token}` : ''
  }
  return new Promise((resolve, reject) => {
    return axios.post('/api/auth/refresh', payload, { baseURL: process.env.VUE_APP_BASE_URL, headers: headers }).then((response) => {
      if(response.data.error == "Unauthorized" || response.data.error == "token"){
        if(store.getters["auth/authenticated"]) {
          localStorage.removeItem('token');
          store.dispatch("auth/logout");
          router.push({ name: 'Login' });
        }
      }
      else{
        resolve(response)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

api.interceptors.response.use(null, (error) => {
  switch (error.response.status) {
    case 401:
      eventBus.emit("error", { message: "error.login" });
      break;
    case 500:
      eventBus.emit("error", { message: error.response.data.error });
      break;
    case 404:
      eventBus.emit("error", { message: "error.notfound" });
      break;
  }
  return Promise.reject(error);
});
api.interceptors.request.use(function (config) {
  /*const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;*/
  const token = localStorage.getItem('token');
  if(token){
    if(config.url !== "/api/logout"){
      return refreshToken(token).then((response) => {
        localStorage.setItem('token', response.data.token)
        config.headers.Authorization =  response.data.token ? `Bearer ${response.data.token}` : '';
        return Promise.resolve(config)
      })
    }
  }
  return config;
}, (err) => {
  return Promise.reject(err)
});

export default api;
