import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/chrazzi",
    name: "Chaparazzi",
    component: () => import("../views/community/Chrazzi.vue"),
    meta: { title: "Chimboz | Chaparazzi" },
    children: [
      {
        path: ":name",
        component: () => import("../components/Gallery.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: { title: "Chimboz" },
  },
  {
    path: "/admin",
    name: "AdminView",
    component: () => import("../views/Admin.vue"),
    meta: { title: "Chimboz | Administration" },
    children: [
      {
        path: ":id",
        component: () => import("../components/AdminUser.vue"),
        meta: { title: "Chimboz | Administration" },
      },
    ],
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/account/Account.vue"),
    meta: { title: "Chimboz | Mon compte" },
  },
  {
    path: "/rappel",
    name: "Reminder",
    component: () => import("../views/Reminder.vue"),
    meta: { title: "Chimboz | Récupère ton compte" },
  },
  {
    path: "/bank",
    name: "Binks",
    component: () => import("../views/account/Bank.vue"),
    meta: { title: "Chimboz | Banque" },
  },
  {
    path: "/majmin",
    name: "LowUpp",
    component: () => import("../views/account/LowUpp.vue"),
    meta: { title: "Chimboz | MajMin" },
  },
  {
    path: "/popularity",
    name: "Popularity",
    component: () => import("../views/members/Popularity.vue"),
    meta: { title: "Chimboz | Popularité" },
  },
  {
    path: "/friends",
    name: "FriendsView",
    component: () => import("../views/account/Friends.vue"),
    meta: { title: "Chimboz | Mes amis" },
  },
  {
    path: "/games",
    name: "Games",
    component: () => import("../views/games/Games.vue"),
    meta: { title: "Chimboz | Jeux" },
  },
  {
    path: "/games/bacteria",
    name: "Bacteria",
    component: () => import("../views/games/Bacteria.vue"),
    meta: { title: "Chimboz | Bacteria" },
  },
  {
    path: "/games/bacteria/story",
    name: "Bacteria Story",
    component: () => import("../views/games/Story.vue"),
    meta: { title: "Chimboz | Histoire de Bacteria" },
  },
  {
    path: "/games/patojdur",
    name: "Patojdur",
    component: () => import("../views/games/Patojdur.vue"),
    meta: { title: "Chimboz | Patojdur" },
  },
  {
    path: "/games/mazo",
    name: "Mazo",
    component: () => import("../views/games/Mazo.vue"),
    meta: { title: "Chimboz | Mazo" },
  },
  {
    path: "/levels",
    name: "Levels",
    component: () => import("../views/account/Levels.vue"),
    meta: { title: "Chimboz | Niveaux" },
  },
  {
    path: "/members",
    name: "Members",
    component: () => import("../views/members/Members.vue"),
    meta: { title: "Chimboz | Membres" },
  },
  {
    path: "/online",
    name: "Online",
    component: () => import("../views/members/Online.vue"),
    meta: { title: "Chimboz | Connecté(s)" },
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("../views/account/Notsmart.vue"),
    meta: { title: "Chimboz | Pamalin" },
  },
  {
    path: "/book",
    name: "Book",
    component: () => import("../views/members/book/Book.vue"),
    meta: { title: "Chimboz | Annuaire" },
  },
  {
    path: "/book/:id",
    name: "Member",
    component: () => import("../views/members/book/Member.vue"),
    meta: { title: "Chimboz | Membre" },
  },
  {
    path: "/wedding",
    name: "Wedding",
    component: () => import("../views/members/Wedding.vue"),
    meta: { title: "Chimboz | Mariage" },
  },
  {
    path: "/groups",
    name: "Groups",
    component: () => import("../views/members/group/Groups.vue"),
    meta: { title: "Chimboz | Groupes" },
  },
  {
    path: "/groups/:id",
    name: "Group",
    component: () => import("../views/members/group/Group.vue"),
    meta: { title: "Chimboz | Groupe" },
  },
  {
    path: "/groups/manage",
    name: "Group Manage",
    component: () => import("../views/members/group/Manage.vue"),
    meta: { title: "Chimboz | Gestion" },
  },
  {
    path: "/groups/edit/:id",
    name: "Group Edit",
    component: () => import("../views/members/group/Edit.vue"),
    meta: { title: "Chimboz | Groupe" },
  },
  {
    path: "/groups/create",
    name: "Group Create",
    component: () => import("../views/members/group/Create.vue"),
    meta: { title: "Chimboz | Création d'un groupe" },
  },
  {
    path: "/bbs",
    name: "BBS",
    component: () => import("../views/community/bbs/BBS.vue"),
    meta: { title: "Chimboz | BBS" },
  },
  {
    path: "/bbs/:id/:page?",
    name: "Forum", // TODO
    component: () => import("../views/community/bbs/Board.vue"),
    meta: { title: "Chimboz | BBS" },
  },
  {
    path: "/topic/:id/:topic/:page?",
    name: "Topic", // TODO
    component: () => import("../views/community/bbs/Topic.vue"),
    meta: { title: "Chimboz | BBS" },
  },
  {
    path: "/shop",
    name: "Shop", // TODO
    component: () => import("../views/community/Shop.vue"),
    meta: { title: "Chimboz | Boutique" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { title: "Chimboz | Se connecter" },
  },
  {
    path: "/messenger",
    name: "Messenger",
    component: () => import("../views/account/Messenger.vue"),
    meta: { title: "Chimboz | Mes messages" },
  },
  {
    path: "/messenger/:id",
    name: "Conversation",
    component: () => import("../views/account/Conversation.vue"),
    meta: { title: "Chimboz | Message" },
  },
  {
    path: "/name",
    name: "Eraser",
    component: () => import("../views/community/Eraser.vue"),
    meta: { title: "Chimboz | Effaceur" },
  },
  {
    path: "/encyclopedia",
    name: "Encyclopedia",
    component: () => import("../views/community/Encyclopedia.vue"),
    meta: { title: "Chimboz | Items" },
    children: [
      {
        path: ":id",
        component: () => import("../components/Item.vue"),
        meta: { title: "Chimboz | Items" }
      }
    ]
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: () => import("../views/Error.vue"),
    meta: { title: "Chimboz | Erreur" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    return { top: 0, behavior: "smooth" };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const guestOnly = ["Login", "Reminder"];
  const userOnly = ["AdminView", "Account", "Home", "Messenger", "Password", "Conversation", "Binks", "Eraser", "LowUpp", "FriendsView", "Group Edit", "Group Create", "Group Manage"];
  if (store.getters["auth/authenticated"]) {
    const role = store.state["auth"]["user"]["role"];
    if (guestOnly.includes(to.name)){
      router.push({ name: "Error", params: { message: "error.connected" } });
    }
    else if(to.name == "AdminView" && role == 0){
      router.push({ name: "Home" });
    }
    else next();
  } else {
    if (userOnly.includes(to.name)) next({ name: "Login" });
    else next();
  }
});

export default router;