<template>
  <ProgressBar />
  <Navbar />
  <router-view />
  <Footer />
  <Modal />
</template>

<script>
import ProgressBar from "./core/ProgressBar.vue";
import Navbar from "./core/Navbar.vue";
import Footer from "./core/Footer.vue";
import Modal from "./core/Modal.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
    ProgressBar,
    Modal
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
