import { createApp } from "vue";
import App from "./components/ExampleComponent.vue";
import eventBus from "./module/eventBus";
import api from "./module/api.js";
import "./assets/scss/var.scss";
import "./assets/scss/main.scss";
import "./assets/scss/fonts.css";
import "./assets/scss/classes.scss";
import "./assets/scss/markdown.css";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store/index.js";
import i18n from "./i18n";
import "highlight.js/scss/github-dark-dimmed.scss";
import "katex/dist/katex.min.css";

//components
import Avatar from "./components/core/global/Avatar.vue";
import SideNavEntries from "./components/core/global/SideNavEntries.vue";
import Container from "./components/core/global/Container.vue";
import Card from "./components/core/global/Card.vue";
import Button from "./components/core/global/Button.vue";
import Rules from "./components/core/global/Rules.vue";
import User from "./components/core/global/link/User.vue";
import './registerServiceWorker';

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(VueAxios, axios)
  .use(store)

app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.api = api;
app.config.globalProperties.sectionVariable = 0;

app.component("Tiz", Avatar);
app.component("SideNavEntries", SideNavEntries);
app.component("Container", Container);
app.component("Card", Card);
app.component("Button", Button);
app.component("Rules", Rules);
app.component("User", User);
app.mount("#app");

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (typeof to.matched[0]?.components.default === "function") {
    eventBus.emit("asyncComponentLoading", to); // Start progress bar
  }
  next();
});

router.beforeResolve((to, from, next) => {
  eventBus.emit("asyncComponentLoaded"); // Stop progress bar
  next();
});

export default app;