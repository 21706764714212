<template>
  <footer>
    <hr />
    <br />
    <a href="https://twitter.com/ChimbozOfficial" target="_blank"
      ><img draggable="false" 
        alt="Twitter follow"
        title="Twitter follow"
        src="https://img.shields.io/twitter/follow/ChimbozOfficial?color=blue&label=Twitter&logo=twitter&style=flat" /></a
    >&nbsp;
    <a href="https://discord.gg/aYph9u8" target="_blank"
      ><img draggable="false" 
        alt="Discord server"
        title="Discord server"
        src="https://img.shields.io/discord/765491519536496660?label=discord&logo=discord&logoColor=fff" /></a
    >&nbsp;
    <a href="http://www.gnu.org/licenses/gpl-3.0" target="_blank"
      ><img draggable="false" 
        alt="License GPL v3"
        title="License GPL v3"
        src="https://img.shields.io/badge/license-GPL%20v3-blue.svg" /></a
    >&nbsp;
    <a href="https://github.com/Tigriz/chzretro-front" target="_blank"
      ><img draggable="false" 
        alt="Front version"
        title="Front version"
        src="https://img.shields.io/github/package-json/v/tigriz/chzretro-front"
    /></a>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  margin-top: 12px;
  padding: 0 10px;
  text-align: center;
}
</style>